import { booleanAttribute, Directive, ElementRef, Input } from '@angular/core';

@Directive({
    standalone: true,
    selector: '[element-focus]',
})
export class ElementFocusDirective {
    @Input({ alias: 'element-focus', transform: booleanAttribute })
    set focusElement(focus: boolean) {
        if (focus) {
            setTimeout(() => {
                this.el.nativeElement.focus();
            }, 200);
        }
    }

    constructor(private el: ElementRef) {}
}
