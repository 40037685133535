import { booleanAttribute, Directive, Input, TemplateRef } from '@angular/core';

@Directive({
    standalone: true,
    selector: '[customDialogContent]',
})
export class CustomDialogContentDirective {
    @Input() dialogClass: string;
    @Input({ transform: booleanAttribute, alias: 'remove-padding-top' }) removePaddingTop: boolean;

    constructor(public template: TemplateRef<any>) {}
}
